<template>
	<div>
		<div  class="bg-left">
			<img class="cover_left" src="../../res/img/cover-left.png">
		</div>
		<div>
			<img class="bg-rigth" src="../../res/img/login-bj-rigth.png">
			<div class="login">
			<img class="logo" src="../../res/img/logo.png">

			<!-- 登录表单 -->
			<div style="margin-top:30px">
				<div style="margin-top:10px">
					<div class="login_type">
						<div v-if="login_identity==1" :class="(login_type=='admin')?'login_type_tle login_chang':'login_type_tle' " @click="login_type_chang('admin')">
							货主登录
						</div>
						<div :class="(login_type=='son')?'login_type_tle login_chang':'login_type_tle' " @click="login_type_chang('son')">
							子账户登录
						</div>
					</div>
					<div class='input'>
						<input type="text" class="input_tel" @input="tel_input"  v-model="tel" placeholder="请输入手机号">
						<div class="verifyTips" v-if="psw_show">
							<input class="input_verify"  type="text" v-model="check_code" placeholder="请输入验证码">
							<div class="verify_text"  @click="get_verify">{{verifyTips}}</div>
						</div>
						
						<!-- 密码登陆 -->
						<input  v-if="!psw_show" type="password" class="psw"  v-model="login_pwd"  placeholder="请输入登录密码">
	
					</div>
					
					<div class="logo_type_chang"  @click="psw_show = !psw_show">
						{{psw_show ? '使用密码登录' :'使用验证码登录'}}
					</div>
					<div class="btn" @click="login">
						登录
					</div>
					 <div class="bottom_links">
						<div class="text">登录即代表您已阅读并同意</div>
						<el-link type="primary" class="item">《个人信息管理规则》</el-link>
						<el-link type="primary" class="item">《交易规则和保障条款》</el-link>
						<el-link type="primary" class="item">《电子签名数字证书用户使用须知》</el-link>
						<el-link type="primary" class="item">《用户授权协议》</el-link>
					</div>
					<!-- 登录 -->
					<!-- <el-form status-icon label-width="px" label-position="left" > -->
						<!-- <el-form-item prop="tel" class="input_tel">
							<el-input v-model="tel" placeholder="请输入手机号" clearable></el-input>
						</el-form-item> -->
						  <!-- <el-button class="btn_left" size="mini" type="text" @click="psw_show = !psw_show">{{psw_show ? '密码登陆' :'验证码登陆'}}</el-button> -->
						  <!-- 密码登陆 -->
						<!-- <el-form-item prop="check_code" v-if="!psw_show">
							<el-input v-model="login_pwd" type="password" :show-password="true" placeholder="请输入登录密码" clearable>
								<template slot="prepend">登录密码</template>
							</el-input>
						</el-form-item> -->
						<!-- 验证码登陆 -->
						<!-- <el-form-item prop="check_code" v-if="psw_show">
							<el-input v-model="check_code" placeholder="请输入验证码" clearable>
								<template slot="prepend">验证码</template>
								<el-button @click="get_verify" type="success" slot="append" style="width:112px">{{verifyTips}}</el-button>
							</el-input>
						</el-form-item>
					</el-form> -->
				</div>
			</div>
			
			<!-- action -->
			<!-- <div class="bottom_links">
				<div class="text">登录即同意</div>
				<div class="links">
					<el-link type="primary" class="item">《个人信息管理规则》</el-link>
					<el-link type="primary" class="item">《交易规则和保障条款》</el-link>
					<el-link type="primary" class="item">《电子签名数字证书用户使用须知》</el-link>
					<el-link type="primary" class="item">《用户授权协议》</el-link>
				</div>
			</div>
			<div class="sub_btn">
				<el-button @click="login('admin')" style="width: 100%;" type="primary">立即登录</el-button>
				<el-button @click="login('son')" style="width: 100%;margin-top: 15px;margin-left:-1px" type="primary">子账户登陆</el-button>
			</div> -->
		</div>
			</div>
			
		<!-- <div class="loginContent">
		
		</div> -->
	</div>
</template>

<script>
	import {mapMutations,mapState} from 'vuex'
	export default {
		data() {
			return {
				//login_way:1,//登陆方式(1:短信验证码,2:密码)
				tel: '',
				login_pwd:'',
				check_code: '',
				sec:60,
				verifyTips: '获取验证码',
				login_type:'admin',//登陆方式 admin货主 son子账户 默认货主登陆
				login_lock:false,
				check_code_lock:false,
				psw_show:true,//登陆方式(true:短信验证码,false:密码)

				login_identity:'1',//登陆身份
			}
		},
		created() {
			this.login_set()
		},
		computed:{
			...mapState(['is_login'])
		},
		methods: {

			...mapMutations(["end_of_login","save_shipper_set","save_company_info"]),
			login_set(){
				if(this.is_login==1){
					//尝试取出期望抵达页面
				let old_path=this.$my.cache.get('_before_login_to_page')
				
				//去哪?
				if(old_path){
						this.$router.push(old_path.fullPath);
				}else{
						this.$router.push('/pages/cargo/cargo_creat');
				}
				}
			},
			//登陆方式切换
			login_type_chang(type){
				this.login_type = type
			},
			//手机号身份校验
			tel_input(item){
				if(this.tel == '18855370372'){
					return
				}
				if(this.tel.length == 11){
					this.$my.net.req({
						data:{
							mod:'app_user',
							ctr:'get_user_type',
							tel:this.tel
						},callback:(data)=>{
							if(data !=1){
								this.login_type ='son'
								this.login_identity = data
							}
						}
					})
				}
			},
			getCookie(name) {
				const cookieString = document.cookie;
				const cookies = cookieString.split('; ');
				for (const cookie of cookies) {
				const [cookieName, cookieValue] = cookie.split('=');
				if (cookieName === name) {
					return cookieValue;
				}
				}
				return null;
			},
			//登录
			login(){

				//是否锁定
				if(this.login_lock){
					this.$my.other.msg({
						type:'warning',
						str:'登录中,不要重复点击',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}
				var ctr=''
				//通过验证码登录
				if(this.psw_show){
					//验证码校验
					if(!(this.$my.check.is_num(this.check_code)&&this.check_code.length==4)){
						this.$my.other.msg({
							type:'warning',
							str:'验证码只能是4位数字',
						})
						return;
					}
					ctr = "app_user_login_reg"
					this.login_pwd = ''
					// this.login_by_check_code(user_id);

				//通过密码登录
				}else if(!this.psw_show){
					if(!this.$my.check.is_login_pwd(this.login_pwd)){
						this.$my.other.msg({
							type:'warning',
							str:'密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数',
						})
						return;
					}
					ctr = 'app_user_login_by_pwd'
					this.check_code = ''
					// this.login_by_pwd(user_id);
				}
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr,
						tel:this.tel,
						ide:this.check_code,
						login_pwd:this.login_pwd,
					},
					callback:(data)=>{
						//缓存用户数据
						this.end_of_login({
							is_login:1,
							user_info:data.user_info,
						});
						localStorage.setItem('user_id',this.login_type);
						// localStorage.setItem('oid',data.user_info.id);
						// var a =this.getCookie('id')
						// if(!a){
						// 	console.log(111)
						// 	document.cookie='id='+data.user_info.id
						// }
						localStorage.setItem('login_set',1);
						if(this.login_type =='son'){
							this.$router.push('/pages/sub_user/my_main_user_list');
							return
						}
						//尝试读取货源系统独有数据
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'shipper_extend_info_by_shipper',
								get_shipper_set:1,
								get_company:1,
							},
							callback:(shipper_info)=>{

								//缓存数据
								this.save_shipper_set(shipper_info.shipper_set); 
								this.save_company_info(shipper_info.company_info);
								
								//尝试取出期望抵达页面
								let old_path=this.$my.cache.get('_before_login_to_page')
								
								//去哪?
								if(old_path){
									 this.$router.push(old_path.fullPath);
								}else{
									 this.$router.push('/pages/cargo/cargo_creat');
								}
							}
						});
					}
				});
			},
			
			//获取验证码
			get_verify(){

				//是否锁定
				if(this.check_code_lock){
					this.$my.other.msg({
						type:'warning',
						str:'60秒内不能重复获取验证码',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//发送中...
				this.verifyTips="发送中...";
				
				//发送请求
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'send_ide_for_login_reg',
						tel:this.tel
					},
					callback:(data)=>{
						
						//锁定发送按钮
						this.check_code_lock=true;
						
						this.verifyTips=this.sec+"S";

						//开始倒计时
						this.interval_id=setInterval(()=>{
							this.sec--;
							this.verifyTips=this.sec+"S";
							if(this.sec==0){
								this.check_code_lock=false;
								this.sec=60;
								this.verifyTips="重新发送";
								clearInterval(this.interval_id)
							}
						},1000);
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>
	.bg-left{
		width: 50%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: url(../../res/img/login-bj-left.png) ;
		background-size: cover;
		box-shadow: 20px 20px 15px #ddd;
		border-top-right-radius:25px;
		border-bottom-right-radius: 25px;
	}
	.bg-rigth{
		width: 50%;
		height: 100%;
		position: fixed;
		right: 0;
		top: 0;
		// background: url() no-repeat 50%;
		filter: opacity(26%);
	}
	.logo{
		width: 120px;
		height: 120px;
		padding-top: 10px;
	}
	.login{
		text-align: center;
		width: 25%;
		background: #fff;
		padding: 20px 20px;
		border-radius: 20px;
		position: fixed;
		right: 11%;
		top: 20%;
		box-shadow: 6px 0px 10px 0px #ddd;
		border: 1px solid #F2EADC;
	}
	.login_type{
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		color: #795a25;
		font-weight: bold;
		margin-left: 30px;
		margin-right: 30px;
		font-size: 18px;
		cursor:pointer;
	}
	.input{
		width: 85%;
		margin-left: 35px;
	}
	.input_tel{
		width: 100%;
		height: 50px;
		border-radius: 10px;
		padding-left: 10px;
		border: 1px solid #795a25;
		margin-bottom: 20px;
		color: #795a25;
		font-size: 14px;
	}
	.verifyTips{
		width: 99%;
		height: 50px;
		border-radius: 10px;
		padding-right: 16px;
		border: 1px solid #795a25;
		margin-bottom: 5px;
		color: #795a25;
		display: flex;
		.input_verify{
			border: none;
			width: 70%;
			margin-left: 10px;
			font-size: 14px;
			border-right: 1px solid #795a25;
		}
		.verify_text{
			margin-top: 15px;
			margin-left: 10px;
			width: 30%;
			font-weight: bold;
			cursor:pointer
		}
	}
	.psw{
	   	width: 100%;
		height: 48px;
		border-radius: 10px;
		padding-left: 10px;
		border: 1px solid #795a25;
		// margin-bottom: 20px;
		color: #795a25;
		font-size: 14px;
	}
	input::-webkit-input-placeholder{
		color: #BCAC92;
	}
	.logo_type_chang{
		width: 30%;
		// margin-right: 62%;
		margin-left: 5%;
		margin-top: 10px;
		color: #795a25;
		cursor:pointer
	}
	.btn{
		background: #795a25;
		width: 88.5%;
		height: 50px;
		color: #fff;
		border-radius: 10px;
		margin-top: 20px;
		line-height: 50px;
		margin-left: 35px;
		font-weight: bold;
		cursor:pointer
	}
	.bottom_links{
		margin-top: 20px;
		margin-left: 35px;
		width: 100%;
		color: #BCAC92;
		display: flex;
		flex-wrap: wrap;
		// .text{
		// 	margin-right: 38%;
		// }
	}
	.login_chang{
		color:#e0be4d ;
		border-bottom: 2px solid #e0be4d;
	}
	.cover_left{
		filter: opacity(20%);
	}
</style>